<template>
  <div class="row">
    <div class="col-md-12 text-center">
      <h2>Oops! This looks like a black hole.</h2>
      <p>Either you don't have permission to view this page or it doesn't exist.</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'NotFound'
};
</script>
